<template>
  <OtherBanner :type="19" />

  <section class="filter-box" id="pageTop">
    <div class="content">
      <!-- 分类 -->
      <TreeFilter
        :name="$t('category')"
        :dataSource="folderData"
        :treeData2="folderTreeData"
        :treePath2="folderTreePath"
        @onChange="folderChange"
      />
      <!-- 状态 -->
      <ListFilter
        only
        :name="$t('CM_Status')"
        :dataSource="completeType"
        @onChange="typeChange"
      >
        <template v-slot:right>
          <div class="order-box">
            <a
              href="javascript:;"
              :class="{ active: orderActive === 1 }"
              @click="orderChange(1)"
            >
              <ClockCircleFilled />{{ $t("CM_Newest") }}
              <!-- 最新 -->
            </a>
            <a
              href="javascript:;"
              :class="{ active: orderActive === 2 }"
              @click="orderChange(2)"
            >
              <FireFilled />{{ $t("hottest") }}
              <!-- 最热 -->
            </a>
          </div>
        </template>
      </ListFilter>
    </div>
  </section>

  <section class="toolbar">
    <div class="content">
      <div class="crumbs">
        {{ $t("current_position") }}：
        <!-- 当前位置： -->
        <span
          v-for="(item, index) in folderCrumbs"
          :key="item.id"
          @click="crumbsClick(item, index)"
        >
          {{ item.name }}
          <i>></i>
        </span>
      </div>
      <div class="search">
        <div class="search-icon" @click="search">
          <SearchOutlined style="color: #656565" />
        </div>
        <a-input
          class="search-input"
          v-model:value.trim="searchVal"
          :placeholder="$t('please_enter_keywords')"
          allow-clear
          @pressEnter="search"
        />
        <!-- 请输入关键字 -->
        <!-- @change="!searchVal && getSubjectList()" -->
      </div>
    </div>
  </section>

  <section class="subject">
    <div class="content">
      <a-spin :spinning="subjectLoading">
        <template v-if="subjectData.length">
          <div class="cards clearfix">
            <div class="item fl" v-for="item in subjectData" :key="item.taskId">
              <router-link
                class="card"
                :to="{ path: '/subject/detail', query: { id: item.taskId } }"
              >
                <div class="cover">
                  <img :src="item.coverImg" alt="cover" />
                </div>
                <div class="cont">
                  <h5>{{ item.taskName }}</h5>
                  <div class="info">
                    <span>{{ $t("LB_NumPersonLearning", [item.users]) }}</span
                    ><em>|</em>
                    <!-- 人学习 -->
                    <span v-if="item.complete === 0" class="gray">{{
                      $t("CM_NotLearning")
                    }}</span>
                    <!-- 未学习 -->
                    <span v-else-if="item.complete === 1" class="green">{{
                      $t("CM_Completed")
                    }}</span>
                    <!-- 已完成 -->
                    <span v-else class="red">{{ $t("CM_NotCompleted") }}</span>
                    <!-- 未完成 -->
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div class="page-Wrap">
            <a-pagination
              show-quick-jumper
              :defaultPageSize="20"
              v-model:current="currentPage"
              :show-total="(total) => $t('XB_InTotal', [pageTotal])"
              :total="pageTotal"
              @change="pageChange"
            >
              <template #itemRender="{ type, originalElement }">
                <a-button v-if="type === 'prev'">{{ $t("cm_pre") }}</a-button>
                <a-button v-else-if="type === 'next'">{{
                  $t("cm_next")
                }}</a-button>
                <renderVNode v-else :vnode="originalElement"></renderVNode>
              </template>
            </a-pagination>
          </div>
        </template>
        <a-empty v-else style="padding: 50px 0" />
      </a-spin>
    </div>
  </section>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import { useRoute } from "vue-router";
import TreeFilter from "@/components/filter/TreeFilter.vue";
import ListFilter from "@/components/filter/ListFilter.vue";
import OtherBanner from "@/components/banner/OtherBanner.vue";
import { subjectList, subjectFolder } from "@/api/subject";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    TreeFilter,
    ListFilter,
    renderVNode,
    OtherBanner,
  },
  setup() {
    const { t: $t } = useI18n();
    const route = useRoute();
    // 目录
    let folderId = Number(route.query.folderId || 0);
    let completeType = [
      { name: $t("CM_Completed"), id: 1 }, // 已完成
      { name: $t("CM_NotCompleted"), id: 2 }, // 未完成
    ];
    let folderCrumbs = ref([
      {
        id: 0,
        name: $t("CM_All"),
        // 全部
      },
    ]);
    let folderData = ref([]);

    const getNewObj = (data) => {
      return JSON.parse(JSON.stringify(data));
    };

    let tempFolderCrumbs = [];
    let tempFolderData = [];
    let tempFolderPath = [];
    const traverseTree = (data) => {
      let str = `"id":${folderId},`;
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        let strItem = JSON.stringify(item);
        if (strItem.includes(str)) {
          tempFolderCrumbs.push(item);
          tempFolderData.push([
            {
              id: tempFolderData.length == 0 ? 0 : item.id,
              name: $t("CM_All"),
            },
            ...data,
          ]);
          tempFolderPath.push(i);
          if (item.id == folderId && item.list && item.list.length) {
            tempFolderData.push([
              {
                id: tempFolderData.length == 0 ? 0 : item.id,
                name: $t("CM_All"),
              },
              ...item.list,
            ]);
            tempFolderPath.push(-1);
          }
          if (item.id != folderId && item.list && item.list.length) {
            traverseTree(item.list);
          }
          break;
        }
      }
    };

    subjectFolder().then((res) => {
      if (res.ret === 0) {
        folderData.value = res.data || [];
        if (folderId) {
          traverseTree(getNewObj(folderData.value));
          folderCrumbs.value = tempFolderCrumbs;
          folderCrumbs.value = getNewObj(tempFolderCrumbs);
          folderTreeData.value = getNewObj(tempFolderData);
          folderTreePath.value = getNewObj(tempFolderPath);
        }
      }
    });

    let folderTreeData = ref([]);
    let folderTreePath = ref([]);
    const folderChange = ({ activeId, crumbs, reload, treeData, treePath }) => {
      folderId = activeId;
      folderCrumbs.value = crumbs;
      folderTreeData.value = treeData;
      folderTreePath.value = treePath;
      getSubjectList();
    };

    // 格式
    let completeActive = [];
    const typeChange = (arr) => {
      console.log(arr);
      completeActive = arr[0] === 0 ? [] : arr;
      getSubjectList();
    };

    // 排序
    const orderActive = ref(1),
      orderChange = (type) => {
        orderActive.value = type;
        getSubjectList();
      };

    // 面包屑
    const crumbsClick = (data, index) => {
      let dom = document.getElementById(`tree_${index}_${data.id}`);
      dom.click();
    };

    // 搜索
    const searchVal = ref(""),
      search = () => {
        currentPage.value = 1;
        getSubjectList();
      };

    // 课程
    const subjectData = ref([]),
      subjectLoading = ref(true),
      currentPage = ref(1),
      pageTotal = ref(1),
      getSubjectList = (scroll = false) => {
        subjectLoading.value = true;
        subjectList({
          page: currentPage.value,
          pageSize: 21,
          name: searchVal.value,
          folderId: folderId,
          order: orderActive.value,
          complete: completeActive,
        }).then((res) => {
          subjectLoading.value = false;
          if (res.ret === 0) {
            pageTotal.value = res.data.totals;
            subjectData.value = res.data.list || [];
            if (scroll) {
              setTimeout(() => {
                document.getElementById("pageTop").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }, 0);
            }
          }
        });
      },
      pageChange = (n) => {
        currentPage.value = n;
        getSubjectList(true);
      };
    getSubjectList();

    return {
      folderData,
      folderTreeData,
      folderTreePath,
      folderCrumbs,
      folderChange,
      crumbsClick,
      completeType,
      typeChange,
      orderActive,
      orderChange,
      searchVal,
      search,
      getSubjectList,
      subjectData,
      subjectLoading,
      currentPage,
      pageTotal,
      pageChange,
    };
  },
};
</script>

<style lang="less" scoped>
.filter-box {
  background-color: @color-page-gray;
  padding-bottom: 14px;
  .content {
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 0 15px 0 rgba(149, 149, 149, 0.15);
    .mixinWrap();
    .order-box {
      height: 34px;
      .mixinFlex(flex-end; center);
      a {
        font-size: 14px;
        color: #666;
        margin-left: 26px;
        span {
          padding-right: 4px;
        }
        &:hover,
        &.active {
          color: @color-theme;
        }
      }
    }
  }
}

.toolbar {
  background-color: @color-page-gray;
  .content {
    .mixinWrap();
    .mixinFlex(space-between; center);
    padding: 20px 0 16px;
    .crumbs {
      width: calc(100% - 250px);
      font-size: 14px;
      color: #999;
      span {
        cursor: pointer;
        i {
          padding: 0 2px;
        }
        &:hover {
          color: @color-theme;
        }
        &:last-child {
          .noevents();
          i {
            display: none;
          }
          &:hover {
            color: #999;
          }
        }
      }
    }
    .search {
      border: 1px solid #ccc;
      .mixinFlex(space-between; center);
      width: 220px;
      height: 32px;
      background-color: #fff;
      border-radius: 16px;
      overflow: hidden;
      &-icon {
        width: 34px;
        height: 14px;
        .mixinFlex(center; center);
        border-right: 1px solid #bbbbbb;
        cursor: pointer;
      }
      ::v-deep(.ant-input-affix-wrapper-focused) {
        border-color: none;
        box-shadow: none;
      }
      &-input {
        border: none;
        width: calc(100% - 34px);
        height: 100%;
        background-color: #fff;
        padding: 0 8px;
        font-size: 14px;
        ::v-deep(.ant-input) {
          background-color: #fff;
        }
      }
    }
  }
}

.subject {
  background-color: @color-page-gray;
  .content {
    .mixinWrap();
    padding: 22px 0 42px;
    .cards {
      .item {
        margin-right: 24px;
        margin-bottom: 24px;
        &:nth-child(3n) {
          margin-right: 0;
        }
        .card {
          display: block;
          width: 384px;
          height: 257px;
          border-radius: 8px;
          box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.25);
          background-color: #fff;
          transition: all 0.3s ease;
          position: relative;
          .cover {
            .mixinImgWrap(384px; 174px);
            border-radius: 8px 8px 0 0;
            overflow: hidden;
            position: relative;
            &::after {
              content: "";
              background-color: rgba(255, 255, 255, 0);
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              transition: all 0.3s ease;
            }
          }
          .cont {
            padding: 12px 18px;
            border-radius: 0 0 8px 8px;
            h5 {
              font-size: 16px;
              .mixinEllipsis(24px);
              margin: 0;
            }
            .info {
              color: #666;
              font-size: 14px;
              padding-top: 10px;
              .gray {
                color: #999999;
              }
              .green {
                color: #1ab700;
              }
              .red {
                color: #e63450;
              }
              em {
                font-size: 1px;
                margin: 0 15px;
                color: #d8d8d8;
              }
            }
          }
          &:hover {
            transform: translateY(-4px);
            box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.25);
            .cover {
              &::after {
                background-color: rgba(255, 255, 255, 0.1);
              }
            }
          }
        }
      }
    }
  }
}
</style>
